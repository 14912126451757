import { NextSeo } from 'next-seo'
import { useLocalizedUrl } from '@/hooks/useLocalizedUrl'
import { useRouter } from 'next/router'

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL

export function Seo({
  title,
  description,
  path,
  thumbnail,
}: {
  title: string
  description: string
  thumbnail?: string
  path: string
}) {
  const localizedUrl = useLocalizedUrl(path)
  const router = useRouter()
  const { locale, defaultLocale } = router

  const images = thumbnail ? [
    {
      url: thumbnail,
      alt: title,
    },
  ] : []

  return (
    <NextSeo
      title={title}
      description={description}
      canonical={localizedUrl}
      openGraph={{
        url: localizedUrl,
        type: 'website',
        title,
        description,
        images,
      }}
      languageAlternates={[
        {
          hrefLang: 'zh',
          href: `${BASE_URL}${path}`,
        },
        {
          hrefLang: 'en',
          href: `${BASE_URL}/en${path}`,
        },
      ].filter(x => x.hrefLang !== locale)}
    />
  )
}
