import { useRouter } from 'next/router'

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL

export function useLocalizedUrl(path: string) {
  const router = useRouter()
  const { locale, defaultLocale } = router

  return locale === defaultLocale
    ? `${BASE_URL}${path}`
    : `${BASE_URL}/${locale}${path}`
}

