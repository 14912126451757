import * as React from "react"
import { cn } from "@/utils/cn"
import { useLabels } from "@/hooks/useDevtool"

export default function Navbar({
  onLabelClick,
  activeLabel,
}: {
  onLabelClick: (label: string) => void
  activeLabel: string | null
}) {
  const categories = useLabels();

  return (
    <nav className="flex flex-col space-y-2 bg-gray-100 rounded-lg px-2 py-4">
      {categories.map((category) => (
        <div
          key={category.key}
          className={cn(
            "p-2 rounded transition-colors duration-300 flex items-center cursor-pointer text-orange-500",
            activeLabel === category.key && "bg-orange-500 text-white"
          )}
          onClick={() => {
            onLabelClick(category.key)
          }}
        >
          <category.icon className="w-5 h-5 mr-2" />
          {category.label}
        </div>
      ))}
    </nav>
  )
}
