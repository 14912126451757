import { useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import type { GetStaticPropsContext } from 'next'
import { useLocalStorage } from 'react-use'
import { useTranslations } from 'next-intl'
import { Switch } from "@/components/ui/switch"

import Header from '@/components/layouts/header'
import Navbar from '@/components/layouts/navbar'
import { Tool, useDevtools, useLabels } from '@/hooks/useDevtool'
import { getToolsByLabel } from '../utils/label'
import { ToolsBlock, StarTools } from '../components/tools'
import { Seo } from '@/components/seo'

function Home() {
  const { query, isReady } = useRouter()
  const t = useTranslations('Index')
  const devtools = useDevtools()
  const labels = useLabels()

  const [label, setLabel] = useState<string | null>(null)
  const labelRef = useRef<HTMLDivElement>(null)

  const [starTools, setStarTools] = useLocalStorage<Tool[]>('Home:Tools:ME', [])
  const [showThumbnail, setShowThumbnail] = useLocalStorage('Home:ShowThumbnail', false)

  useEffect(() => {
    setLabel(isReady ? query.label as string : null)
  }, [isReady, query.label])

  useEffect(() => {
    if (label && labelRef.current) {
      labelRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [label])

  const onStar = useCallback((tool: Tool) => {
    const isStar = starTools?.map(x => x.href).includes(tool.href)
    const tools = !isStar ? [
      ...starTools || [],
      tool,
    ] : starTools?.filter(x => x.href !== tool.href)
    setStarTools(tools)
  }, [starTools])

  return (
    <div className="container min-h-screen">
      <Seo
        title={t('title')}
        description={t('description')}
        path=""
      />
      <Header />
      <div className="w-full grid grid-cols-[180px,1fr] gap-8 mt-2 min-h-[calc(100vh-80px)]">
        <div className="sticky top-20 h-fit">
          <Navbar onLabelClick={setLabel} activeLabel={label} />
          <div className="flex items-center gap-2 mt-4 px-2">
            <span className="text-sm text-gray-500">{t('showThumbnail')}</span>
            <Switch
              checked={showThumbnail}
              onCheckedChange={setShowThumbnail}
            />
          </div>
        </div>
        <div className="flex-1">
          <StarTools 
            starTools={starTools || []} 
            onStar={onStar}
            showThumbnail={showThumbnail} 
          />
          {
            labels.filter(label => label.key !== 'star').map((labelItem, index) => {
              return (
                <div key={labelItem.key} ref={labelItem.key === label ? labelRef : null}>
                  <ToolsBlock
                    label={labelItem.key}
                    tools={getToolsByLabel(devtools, labelItem.key)}
                    onStar={onStar}
                    starTools={starTools || []}
                    className={index === 0 ? 'border-t' : ''}
                    showThumbnail={showThumbnail}
                  />
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export async function getStaticProps({locale}: GetStaticPropsContext) {
  return {
    props: {
      messages: (await import(`../messages/${locale}.json`)).default
    }
  };
}

export default Home
